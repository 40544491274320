.button {
  $root: &;
  font-family: inherit;
  cursor: pointer;
  line-height: $buttonPrimaryHeightMobile;
  text-align: center;
  padding: 0 8px;
  width: auto;
  position: relative;
  color: $buttonTextColor;
  font-weight: $buttonFontWeight;
  background: $buttonBackgroundColor;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: $buttonFontSizeMobile;
  border: $buttonBorderWidth solid $buttonBorderColor;
  border-radius: $buttonBorderRadius;

  --button-primary-text-color: #{$buttonPrimaryTextColor};
  --button-primary-background-color: #{$buttonPrimaryBackgroundColor};
  --button-primary-border-color: #{$buttonPrimaryBorderColor};
  --button-primary-hover-background-color: #{$buttonPrimaryHoverBackgroundColor};
  --button-primary-hover-text-color: #{$buttonPrimaryHoverTextColor};
  --button-primary-active-background-color: #{$buttonPrimaryActiveBackgroundColor};
  --button-secondary-text-color: #{$buttonSecondaryTextColor};
  --button-secondary-background-color: #{$buttonSecondaryBackgroundColor};
  --button-secondary-border-color: #{$buttonSecondaryBorderColor};
  --button-secondary-hover-background-color: #{$buttonSecondaryHoverBackgroundColor};
  --button-secondary-hover-text-color: #{$buttonSecondaryHoverTextColor};
  --button-secondary-active-background-color: #{$buttonSecondaryActiveBackgroundColor};

  @media (min-width: $screen-tablet-portrait) {
    font-size: $buttonFontSizeDesktop;
    line-height: $buttonPrimaryHeightDesktop;
  }

  @media (min-width: $screen-tablet-landscape) {
    padding: 0 24px;
  }

  &--hover,
  &:hover,
  &:focus {
    color: $buttonHoverTextColor;
    background: $buttonHoverBackgroundColor;
    text-decoration: none;
  }

  &:focus {
    outline-offset: 4px;
  }

  &--active,
  &:active {
    outline: none;
  }

  &--loading:not(.button--hide-loading) {
    pointer-events: none;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      cursor: not-allowed;
      z-index: 10;
      position: absolute;
      background: inherit;
      left: 0;
      top: 0;
    }

    &::after {
      font-family: iconfont, sans-serif;
      content: map-get($icons, 'loading') !important;
      cursor: not-allowed;
      z-index: 20;
      position: absolute;
      animation: spin 4s linear infinite;
      font-size: 26px !important;
      width: 26px;
      font-weight: 100 !important;
      margin: auto;
      padding: 0 !important;
      line-height: 26px !important;
      height: 26px;
      top: -300px;
      right: -300px;
      bottom: -300px;
      left: -300px;
    }

    &:hover {
      background: $buttonBackgroundColor;
    }
  }

  &--link {
    background: transparent;
    display: inline-block;
    width: auto;
    border: 0 none;
    color: $grayDarkColor;
    text-decoration: underline;
    padding: 0;

    &:hover,
    &:focus {
      color: $grayDarkColor;
      background: transparent;
      text-decoration: underline;
    }

    &:active {
      outline: none;
    }
  }

  &--delete {
    padding: 0 8px;
    border-color: var(--button-secondary-border-color);
    font-size: $buttonFontSizeMobile !important;

    &--hover,
    &:hover,
    &:focus {
      background: var(--button-secondary-background-color);
      color: var(--button-secondary-text-color);
    }
  }

  &--height-auto {
    height: auto;
    line-height: normal;
  }

  &--primary {
    background: var(--button-primary-background-color);
    border-color: var(--button-primary-border-color);
    color: var(--button-primary-text-color);
    display: inline-block;
    width: 100%;
    font-weight: $buttonFontWeight;
    text-transform: uppercase;
    padding: 0 16px;
    font-size: $buttonFontSizeMobile;

    @media (min-width: $screen-mobile-landscape) {
      min-width: 200px;
      padding: 0 32px;
      width: auto;
    }

    @media (min-width: $screen-tablet-portrait) {
      font-size: $buttonFontSizeDesktop;
    }

    &#{$root}--hover,
    &:hover,
    &:focus {
      background: var(--button-primary-hover-background-color);
      color: var(--button-primary-hover-text-color);
    }

    &#{$root}--active,
    &:active {
      background: var(--button-primary-active-background-color);
    }

    &.button--loading::before {
      background: var(--button-primary-background-color);
    }

    &#{$root}--disabled {
      background: rgb(222 223 92 / .5);
      border-color: rgb(222 223 92 / .5);
      color: rgb(74 73 28 / .5);

      &:hover {
        background: rgb(222 223 92 / .5);
        border-color: rgb(222 223 92 / .5);
        color: rgb(74 73 28 / .5);
      }
    }
  }

  &--primary-small {
    padding: 0 32px;
    background: var(--button-primary-background-color);
    color: $buttonPrimaryTextColor;
    display: inline-block;
    width: auto;
    border-color: var(--button-primary-border-color);
    text-transform: uppercase;
    font-size: $buttonFontSizeMobile;
    height: 32px;
    line-height: 32px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $buttonFontSizeDesktop;
      line-height: 32px;
    }

    &#{$root}--hover,
    &:hover,
    &:focus {
      background: var(--button-primary-hover-background-color);
      color: var(--button-primary-hover-text-color);
    }

    &#{$root}--active,
    &:active {
      background: var(--button-primary-active-background-color);
    }

    &#{$root}--disabled {
      background: rgb(222 223 92 / .5);
      border-color: rgb(222 223 92 / .5);
      color: rgb(74 73 28 / .5);

      &:hover {
        background: rgb(222 223 92 / .5);
        border-color: rgb(222 223 92 / .5);
        color: rgb(74 73 28 / .5);
      }
    }
  }

  &--secondary {
    background: var(--button-secondary-background-color);
    color: var(--button-secondary-text-color);
    display: inline-block;
    width: auto;
    font-weight: $buttonFontWeight;
    text-transform: uppercase;
    border-color: var(--button-secondary-border-color);
    padding: 0 16px;
    font-size: $buttonFontSizeMobile;
    line-height: 42px;

    &#{$root}--hover,
    &:hover,
    &:focus {
      background: var(--button-secondary-hover-background-color);
      color: var(--button-secondary-text-color);
    }

    &#{$root}--active,
    &:active {
      background: var(--button-secondary-active-background-color);
    }

    @media (min-width: $screen-mobile-landscape) {
      min-width: 200px;
      padding: 0 32px;
    }

    @media (min-width: $screen-tablet-portrait) {
      font-size: $buttonFontSizeDesktop;
    }

    &.button--loading::before {
      background: var(--button-secondary-background-color);
    }

    &#{$root}--disabled {
      background: rgb(74 73 28 / 0);
      border-color: rgb(74 73 28 / .5);
      color: rgb(74 73 28 / .5);

      &:hover {
        background: rgb(74 73 28 / 0);
        border-color: rgb(74 73 28 / .5);
        color: rgb(74 73 28 / .5);
      }
    }
  }

  &--secondary-small {
    padding: 0 32px;
    background: var(--button-secondary-background-color);
    color: var(--button-secondary-text-color);
    display: inline-block;
    font-weight: $buttonSmallFontWeight;
    text-transform: uppercase;
    border-color: var(--button-secondary-border-color);
    font-size: $buttonFontSizeMobile;
    height: 32px;
    line-height: 32px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $buttonFontSizeDesktop;
      line-height: 32px;
    }

    &#{$root}--hover,
    &:hover,
    &:focus {
      background: var(--button-secondary-hover-background-color);
      color: var(--button-secondary-hover-text-color);
    }

    &#{$root}--active,
    &:active {
      background: var(--button-secondary-active-background-color);
    }

    &#{$root}--disabled {
      background: rgb(74 73 28 / 0);
      border-color: rgb(74 73 28 / .5);
      color: rgb(74 73 28 / .5);

      &:hover {
        background: rgb(74 73 28 / 0);
        border-color: rgb(74 73 28 / .5);
        color: rgb(74 73 28 / .5);
      }
    }
  }

  &--secondary-smaller {
    background: var(--button-secondary-background-color);
    color: var(--button-secondary-text-color);
    display: inline-block;
    font-weight: $buttonSmallFontWeight;
    text-transform: uppercase;
    border-color: var(--button-secondary-border-color);
    font-size: 10px;
    line-height: 1;
    white-space: inherit;
    overflow: visible;
    text-overflow: inherit;
    padding: 8px !important;
    height: auto;

    @media (min-width: $screen-tablet-landscape) {
      font-size: 12px;
    }

    &#{$root}--hover,
    &:hover,
    &:focus {
      background: var(--button-secondary-hover-background-color);
      color: var(--button-secondary-hover-text-color);
    }
  }

  &--text {
    font-size: $fontSizeSmall;
    background: none;
    color: $brandPrimaryColor;
    display: inline-block;
    font-weight: $buttonFontWeight;
    text-transform: uppercase;
    text-align: left;
    width: auto;
    height: auto;
    padding: 5px 0;
    line-height: 120%;
    border: 0 none;
    border-bottom: transparent 2px solid;
    white-space: inherit;
    border-radius: 0;

    @media (min-width: $screen-tablet-landscape) {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
    }

    &--hover,
    &:hover,
    &:focus {
      background: none;
      color: $brandPrimaryColor;
      border-bottom: 2px solid $brandPrimaryColor;
    }
  }

  &--cancel {
    background: transparent !important;
    display: inline-block;
    width: auto;
    border: 0 none;
    color: $brandPrimaryColor !important;
    text-decoration: underline !important;
    padding: 0 !important;
    font-weight: $buttonFontWeight !important;
    text-transform: inherit !important;
    text-align: left !important;
    font-size: 14px !important;
    line-height: 24px;

    @media (min-width: $screen-mobile-large) {
      text-align: right !important;
      line-height: $buttonPrimaryHeightDesktop;
    }

    &:hover,
    &:focus {
      color: $brandPrimaryColor !important;
      background: transparent;
      text-decoration: underline;
    }

    &:active {
      outline: none;
    }
  }

  &--width-auto {
    display: inline-block;

    @media (min-width: $screen-mobile-landscape) {
      min-width: auto;
      width: auto;
      padding: 0 16px;
    }
  }

  &--add-to-basket {
    background: $buttonAddToBasketBackgroundColor;
    border-color: $buttonAddToBasketBorderColor;
    color: $greenDarkColor;
    padding: 0;
    width: $buttonAddToBasketWidth;
    height: 45px;
    line-height: 41px;
    font-size: 12px;
    font-weight: normal;

    @include iconFont(cart, before) {
      font-size: 24px;
      transform: translate(-1px, 5px);
    }

    @media (min-width: $screen-desktop) {
      height: $buttonAddToBasketHeight;
      line-height: 36px;
    }

    &:hover,
    &:focus {
      background: $buttonAddToBasketHoverBackgroundColor;
      color: $greenDarkColor;
    }

    &.button--loading::before {
      color: $greenDarkColor;
    }
  }

  &--successor-to-basket {
    background: $buttonAddToBasketBackgroundColor;
    border-color: $buttonAddToBasketBorderColor;
    color: $buttonAddToBasketTextColor;
    padding: 0;
    width: $buttonAddToBasketWidth;
    height: $buttonAddToBasketHeight;
    line-height: 1.2;
    font-size: 12px;
    font-weight: normal;
    text-overflow: inherit;
    white-space: inherit;

    &:hover,
    &:focus {
      background: $buttonAddToBasketHoverBackgroundColor;
      color: $buttonAddToBasketHoverTextColor;
    }

    &.button--loading::before {
      background: $buttonAddToBasketHoverBackgroundColor;
      color: $buttonAddToBasketHoverTextColor;
    }
  }

  &--disabled {
    cursor: not-allowed;
    background: $buttonDisabledBackgroundColor;
    border-color: $buttonDisabledBorderColor;
    color: $buttonDisabledTextColor;

    &:hover {
      background: $buttonDisabledBackgroundColor;
      border-color: $buttonDisabledBorderColor;
      color: $buttonDisabledTextColor;
    }
  }

  &--type {
    &_back {
      padding: 0 30px 0 40px;

      &::after {
        right: auto;
        left: 15px;
        margin-left: 0;
        line-height: 0;
        transform: rotate(-180deg);
      }
    }
  }

  &--icon {
    padding: 0 10px;

    @media (min-width: $screen-tablet-portrait) {
      padding: 0 40px;
    }

    &::after {
      font-size: 12px;
      font-weight: $fontWeightSemiBold;
      position: relative;
      padding: 0 0 2px 10px;
      line-height: 56px;
    }

    &_reload {
      @include iconFont(reload, after);
    }

    &_close {
      @include iconFont(close, after);
    }

    &_next {
      @include iconFont(arrowRight, after);
    }

    &_back {
      @include iconFont(arrowLeft, before) {
        font-size: 12px;
        font-weight: $fontWeightSemiBold;
        position: relative;
        padding: 0 10px 2px 0;
        line-height: 56px;
      }
    }

    &_cart {
      @include iconFont(cart, after) {
        font-size: $fontSizeXMedium;
        font-weight: $fontWeightSemiBold;
      }
    }

    &_edit {
      @include iconFont(edit, after);
    }

    &_remove {
      @include iconFont(trash, after);
    }
  }

  &--paypal-checkout {
    background-color: $paypalcheckout;
    padding: 0;
    max-width: 210px;
    height: $buttonPrimaryHeightMobile;

    @media (min-width: $screen-tablet-portrait) {
      height: $buttonPrimaryHeightDesktop;
    }

    i {
      display: inline-block;
      padding: 0;
      margin: 0;
    }
  }

  &--hide {
    display: none !important;
  }

  &--bg-light-shadow {
    background: rgb(255 255 255 / .5);

    &:hover {
      background: rgb(255 255 255 / .5);
    }
  }

  &--bg-dark-shadow {
    background: rgb(0 0 0 / .5);

    &:hover {
      background: rgb(0 0 0 / .5);
    }
  }

  &--inactive {
    opacity: .4;
  }
}
